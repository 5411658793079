export const menuItems = [
  {title: 'Услуги', href: '/uslugi/', sub: []},
  {title: 'Вакансии', href: '/vacancy/', sub: []},
  {title: 'Портфолио', href: '/portfolio/', sub: []},
  {title: 'Как мы работаем', href: '', sub: [
    {title: 'Методология', href: '/methodology/'},
    {title: 'Технологии', href: '/technology/'},
    {title: 'Flutter', href: '/flutter/'},
  ]},
  {title: 'О компании', href: '/about/', sub: []},
  {title: 'Контакты', href: '/contacts/', sub: []},
  {title: 'Блог', href: '/blog/', sub: []},
]
