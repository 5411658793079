<template lang="pug">
.nav
  Logo
  .nav__burger(@click="toggleSideMenu" :class="isActive ? 'nav__burger_active' : 'nav__burger'")
    span.nav__icon
  //- .nav__back(:class="isActive ? 'nav__back_active' : ''")
  nav.nav__main(:class="{'nav__main_active': isActive}" :style="mobileScreenStyle")
    .logo-wrapper(@click="toggleSubmenu")
      NuxtLink.nav__nuxt-link(to="/")
        //- svg.nav__logo(viewBox="0 0 633 97" aria-hidden="true" focusable="false")
        //-   use.nav__logotype(xlink:href="#logo")
        //- svg.nav__logo-mobile(viewBox="0 0 633 97" aria-hidden="true" focusable="false")
        //-   use.nav__logotype.nav__logotype-mobile(xlink:href="#logo")
        svg(
          viewBox="0 0 633 97"
          aria-hidden="true"
          focusable="false"
          :class="logoClass"
          width="156" height="24")
          use.nav__logotype(
            xlink:href="#logo"
            :class="logoMobileClass")
        span.visually-hidden To home page
    ul.nav__links
      li.nav__link.nav__link-inner(
        v-for="item in menuItems"
        @click="toggleMenu(item.sub.length)")
        NuxtLink.nav__link-inner(
          v-if="item.href"
          :class="{ 'nav__link-inner_dark': darkTheme }"
          :to="item.href") {{ item.title }}
        div.nav__submenu-title.nav__link-inner(
          v-else
          :class="{ 'nav__link-inner_dark': darkTheme }") {{ item.title }}
          <svg class="nav__down-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 213.333 213.333" style="enable-background:new 0 0 213.333 213.333" xml:space="preserve" width="10" height="12"><path fill="currentColor" d="M0 53.333 106.667 160 213.333 53.333z"/>
          </svg>
        ul.nav__sub_menu(
          v-if="item.sub.length"
          :class="linkClassObject")
          li.nav__link-inner.nav__link-inner_sub(
            v-for="subItem in item.sub"
            @click="toggleSubmenu"
            :class="dropdownClass")
            NuxtLink.nav__link-inner(
              :class="{ 'nav__link-inner_dark': darkTheme }"
              :to="subItem.href") {{ subItem.title }}
    NuxtLink.nav__btn(to="/project/")
      BaseBtn.nav__btn(@click="toggleSubmenu") Связаться
    NuxtLink.nav__btn-mobile(to="/project/")
      button(@click="toggleSubmenu") Связаться
</template>

<script>
import { menuItems } from '~/data/mainMenuItems'
import { mobile } from '~/helpers/breakpoints'

export default {
  setup() {
    const isMobile = reactive({})

    return {
      isMobile,
    }
  },
  props: ['darkTheme'],
  data() {
    return {
      isActive: false,
      showSubMenu: false,
      menuItems,
      viewportHeight: null,
      timeoutID: null,
    }
  },
  computed: {
    linkClassObject() {
      return {
        // 'nav__sub_menu-light': !this.isMobile.value && !this.darkTheme,
        'nav__sub_menu_show': this.showSubMenu,
      }
    },
    mobileScreenStyle() {
      return {
        '--max-height': `${this.viewportHeight}px`,
      }
    },
    dropdownClass() {
      return {
        'nav__link--dropdown': !this.isMobile.value,
      }
    },
    logoClass() {
      return this.isMobile.value ? 'nav__logo-mobile' : 'nav__logo'
    },
    logoMobileClass() {
      return {
        'nav__logotype-mobile': this.isMobile.value
      }
    },
  },
  watch: {
    isActive(n, o) {
      if (!n) {
        document.body.style.position = ''
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.closeSubmenu)
    this.viewportHeight = window.innerHeight
    this.isMobile.value = useMediaQuery(mobile)
  },
  unmounted() {
    window.removeEventListener('click', this.closeSubmenu)
  },
  methods: {
    toggleMenu(isSubmenu) {
      if (!isSubmenu) {
        this.isActive = !this.isActive
        this.showSubMenu = false
        if (this.isMobile.value) {
          this.fixedBodyPosition()
        }
      } else {
        this.showSubMenu = !this.showSubMenu
      }
    },
    toggleSubmenu() {
      this.isActive = false
      this.showSubMenu = false
      document.body.style.position = ''
    },
    closeSubmenu(e) {
      const div = document.querySelector('div.nav__submenu-title')
      const ul = document.querySelector('ul.nav__sub_menu')
      const target = e.target

      if (target !== div && target !== ul) {
        this.showSubMenu = false
      }
    },
    toggleSideMenu() {
      this.isActive = !this.isActive
      this.fixedBodyPosition()
    },
    fixedBodyPosition() {
      if (this.isActive) {
        document.body.style.position = 'fixed'
      } else {
        document.body.style.position = ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.nav {
  --logo-color: var(--main-color);

  width: 100%;
  height: 63px;
  min-height: 63px;
  display: flex;
  justify-content: center;
  background: var(--bcg-color);

  @media (--small-viewport-df) {
    --text-color: var(--light-color);
  }

  &__burger {
    width: 26px;
    height: 20px;
    margin-left: 20px;
    margin-top: 20px;
    position: absolute;
    cursor: pointer;
    display: none;
    z-index: 666;

    &_active .nav__icon {
      height: 0;
      background: var(--light-color);

      &::before {
        top: 0;
        transform: rotate(45deg);
        background: var(--light-color);
      }

      &::after {
        bottom: 0;
        transform: rotate(-45deg);
        background: var(--light-color);
      }
    }
  }

  &,
  &__burger {
    @media (--small-viewport-df) {
      display: block;
    }

    @media (--complex-screen) {
      display: block;
    }
  }

  &__icon {
    width: 26px;
    height: 4px;
    display: block;
    background: var(--main-color);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2px;
    margin-left: -13px;

    &::before {
      content: '';
      width: 26px;
      height: 4px;
      position: absolute;
      top: 50%;
      margin-top: -2px;
      left: 50%;
      margin-left: -13px;
      background: var(--main-color);
      transform: translateY(-7px);
      transition: 0.2s;
    }

    &::after {
      content: '';
      width: 26px;
      height: 4px;
      position: absolute;
      top: 50%;
      margin-top: -2px;
      left: 50%;
      margin-left: -13px;
      background: var(--main-color);
      transform: translateY(7px);
      transition: 0.2s;
    }
  }

  &__down-arrow {
    cursor: pointer;
    margin-left: 5px;
    width: 10px;
    padding-bottom: 2px;
  }

  // &__back {
  //   @include mobile {
  //     position: fixed;
  //     left: -100vw;
  //     z-index: 550;
  //     width: 100vw;
  //     height: 200vh;
  //     background: var(--main-color);
  //     transition: left .3s ease-in-out;
  //   }

  //   &_active {
  //     @include mobile {
  //       left: 0;
  //     }
  //   }
  // }

  &__main {
    width: 100%;
    height: 100%;
    padding: 0 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (--medium-range-viewport) {
      padding: 0 20px;
    }

    @media (--small-viewport-df) {
      color: var(--light-color);
      background: var(--main-color);

      position: fixed;
      height: 100vh;
      max-height: var(--max-height, 850px);
      width: 100vw;
      top: 0;
      left: -100vw;
      transition: 0.2s;
      flex-direction: column;
      align-items: center;
      padding: 55px 20px;
      z-index: 555;
      transition: left .3s ease-in-out;
    }

    @media (--complex-screen) {
      position: absolute;
      height: 100vh;
      width: 100%;
      top: -100vh;
      left: 0;
      transition: 0.2s;
      background: var(--main-color);
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 55px 20px;
      z-index: 555;
      display: none;
    }

    &_active {
      @media (--small-viewport-df) {
        left: 0;
        display: flex;
      }

      @media (--complex-screen) {
        top: 0;
        display: flex;
      }
    }
  }

  &__links {
    display: flex;
    margin-right: 80px;
    margin-left: auto;
    margin-bottom: 0;

    @include mobile {
      flex-direction: column;
      margin-right: 0;
      margin: 0 auto 55px;
      justify-content: space-between;
      flex-grow: 2;

      @media screen and (height <=475px) {
        margin: 0 auto 15px;
      }

      @media screen and (height <=600px) {
        padding-left: 40px;
      }
    }

    @media (--complex-screen) {
      flex-direction: column;
      margin-right: 0;
      margin: 0 auto 55px;
    }

    @media (--small-medium-range-viewport) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__link {
    display: flex;
    flex-wrap: wrap;
    max-width: 200px;
    align-items: center;
    margin-right: 20px;
    padding: 0.5rem 1rem;

    @include mobile {
      margin-right: 0;

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (height <=475px) {
        margin-bottom: 10px;
      }
    }

    @media screen and (1024px <=width <=1150px) {
      padding: 0rem 0rem;
    }

    @media (1151px <=width <=1279px) {
      padding: 0.5rem 0.5rem;
    }

    @media (--complex-screen) {
      margin-bottom: 40px;
      margin-right: 0;
      max-width: 220px;
    }
  }

  &__link--dropdown {
    padding: 0.5rem 0 0;
  }

  &__link-inner {
    font-size: 11px;
    color: var(--text-color);
    cursor: pointer;
    text-transform: uppercase;
    position: relative;

    &:hover {
      color: var(--purple-color);
    }

    @media (--small-viewport-df) {
      font-size: 16px;
      // color: var(--bcg-color);
      position: relative;
    }

    @media (--complex-screen) {
      font-size: 18px;
      color: var(--bcg-color);
    }

    &_dark {
      color: var(--text-color);
    }

    &_sub {
      @media (--small-viewport-df) {
        list-style: disc;
        color: white;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }

      @media (--complex-screen) {
        list-style: disc;
        color: white;
        padding-bottom: 5px;
      }
    }
  }

  &__logo {
    width: 156px;

    @media (--small-viewport-df) {
      display: none;
    }

    @media (--complex-screen) {
      display: none;
    }
  }

  &__logo-mobile {
    display: none;
    width: 156px;

    @media (--small-viewport-df) {
      display: block;
      margin-bottom: 55px;
    }

    @media screen and (height <=600px) {
      width: 120px;
      margin: 0 auto 20px auto;
    }

    @media screen and (height >=850px) {
      width: 185px;
    }

    @media (--complex-screen) {
      display: block;
      margin-bottom: 55px;
    }
  }

  &__btn {
    --btn-width: initial;
    --btn-height: initial;
    --btn-font-size: 11px;

    padding: 3px 18px;

    @media (--small-viewport-df) {
      display: none;
    }

    @media (--complex-screen) {
      display: none;
    }
  }

  &__btn-mobile {
    display: none;

    @media (--small-viewport-df) {
      display: block;
    }

    @media (--complex-screen) {
      display: block;
    }
  }

  &__btn-mobile>button {
    text-align: center;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 16px;
    font-weight: 300;
    color: var(--main-color);
    background-color: var(--light-color);
    padding: 7px 25px;
    transition: background-color .3s;

    &:hover {
      background-color: var(--purple-color);
    }

    @media (--extra-samll-viewport-mf) {
      width: auto;
    }

    @media (--small-viewport-df) and (height <=475px) {
      font-size: 14px;
      padding: 5px 10px;
    }
  }

  li:hover>.submenu {
    display: block;
    max-height: 200px;
  }

  &__sub_menu {
    position: absolute;
    z-index: -1;
    top: 40px;
    padding: 0 2.5rem 0.5rem 5px;
    margin-left: -5px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    background: var(--bcg-menu-color);


    @media (--small-viewport-df) {
      padding: 1.5rem 2.5rem;
      top: 2rem;
      left: 0px;
      background: var(--main-color);
      filter: drop-shadow(10px 20px 40px rgba(0, 0, 0, 0.1));
    }

    @media (--complex-screen) {
      padding-left: 30px;
      position: relative;
      top: 10px;
    }

    &_show {
      z-index: 1;
    }

    &_show,
    &_show .nav__link-inner_sub {
      opacity: 1;
    }
  }

  &__sub_menu-light {
    background: var(--bcg-menu-color);
  }

  &__btn {
    @media (--small-range-viewport) {
      max-width: 150px;
    }
  }
}

.nav__link-inner_sub+.nav__link-inner_sub {
  padding-top: 20px;
}

.router-link-active {
  @media (--small-viewport-mf) {
    color: var(--main-color);
  }

  @media (--complex-screen) {
    color: var(--light-color);
  }
}

use.nav__logotype {
  color: var(--text-color);
}

use.nav__logotype-mobile {
  --logo-color: var(--light-color);
  color: var(--dark-color);
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
</style>
